import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import HeroCta from '../components/HeroCta'
import TextBlock from '../components/TextBlock'
import Breadcrumb from '../components/Breadcrumb'

const { siteCode, siteConfig } = require('../../site-config')

const {
  careersPage
} = siteConfig[siteCode]

class JobPostingTemplate extends React.Component {
  render () {
    const post = get(this.props, 'data.contentfulJobPosting')
    const pageTitle = `${get(this.props, 'data.site.siteMetadata.siteName')} jobs / ${post.title}`
    const pageUrl = `${get(this.props, 'data.site.siteMetadata.url')}/team/${post.slug}`

    return (
      <Page>
        <Helmet>
          {pageTitle && <title>{pageTitle}</title>}
          {post.teaser && <meta name='description' content={post.teaser.teaser} />}
          {pageTitle && <meta property='og:title' content={pageTitle} />}
          {pageUrl && <meta property='og:url' content={pageUrl} />}
          <meta property='og:site_name' content={get(this.props, 'data.site.siteMetadata.siteName')} />
        </Helmet>
        <Breadcrumb items={[
          {
            title: careersPage.title,
            path: `/${careersPage.path}`
          },
          {
            title: post.title
          }
        ]}
        />
        <Page.Panel noPadding>
          <TextBlock
            label={post.location}
            headline={post.title}
            bodyText={post.body}
          />
        </Page.Panel>
        <Page.Panel spread color='white'>
          <HeroCta mailto={careersPage.email} buttonText='Send your application' color='brand'>
            If this sounds like a place you'd like to come to work, we humbly suggest we could do some great things together. Please get in touch with a CV or portfolio.
          </HeroCta>
        </Page.Panel>
      </Page>
    )
  }
}

export default JobPostingTemplate

export const pageQuery = graphql`
  query JobPostingBySlug($slug: String!) {
    site {
      siteMetadata {
        siteName
        url
      }
    }
    contentfulJobPosting(slug: { eq: $slug }) {
      slug
      title
      location
      teaser {
        teaser
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
